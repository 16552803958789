<template>
  <div class="profilePage bgfff">
    <div class="app h5MaxWidth">
      <header>
        <div class="header h5MaxWidth clear display alignCenter">
          <div
            class="back display alignCenter justifyCenter"
            onclick="window.history.go(-1)"
          >
            <i class="iconfont icon-arrow-left"></i>
          </div>
          <h2 class="flex1">My Information</h2>
          <div class="navskip" id="sideMenu"></div>
        </div>
      </header>
      <div class="main">
        <div class="profil forms">
          <form id="profil" action="#" method="post" onsubmit="GO">
            <ul>
              <li class="userCard display alignCenter">
                <div class="portrait">
                  <div
                    class="frame radius500PX display alignCenter justifyCenter"
                  >
                    <i class="iconfont icon-my"></i>
                  </div>
                </div>

                <div class="titlePrompt flex1">
                  <span
                    >It is recommended to upload 1:1 images larger than
                    100px</span
                  >
                </div>
              </li>

              <li>
                <div class="inputArea display alignCenter">
                  <div class="leftSideBox display alignCenter">
                    <i class="i_inputIcon iconfont icon-my"></i>
                  </div>
                  <div class="trueInpt display alignCenter flex1">
                    <input
                      type="text"
                      v-model="username"
                      class="userName fillArea flex1"
                      placeholder="User Name"
                      maxlength="40"
                      size="40"
                    />
                  </div>
                </div>
              </li>
              <li>
                <div class="inputArea display alignCenter">
                  <div class="leftSideBox display alignCenter">
                    <i class="i_inputIcon iconfont icon-sms-tracking"></i>
                  </div>
                  <div class="trueInpt display alignCenter flex1">
                    <input
                      type="text"
                      v-model="email"
                      class="email fillArea flex1"
                      placeholder="E-mail"
                      maxlength="40"
                      size="40"
                    />
                  </div>
                </div>
              </li>
            </ul>
            <div class="actionBar display alignCenter alignStretch justifyEnd">
              <div
                @click="submitData()"
                class="submitBinding clickBtn display alignCenter justifyCenter"
              >
                <p>Confirm</p>
                <i class="iconfont icon-arrow-right-3"></i>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      email: "",
      info: {},
    };
  },
  components: {},
  methods: {
    submitData() {
      this.getAjax(
        "api/user/modify",
        {
          uid: this.info.id,
          nickname: this.username,
          avatar: this.info.avatar,
          email: this.email,
        },
        "post"
      ).then((res) => {
        if (res.code === 200) {
          this.tips("success");
        } else {
          this.tips(res.msg, "danger");
        }
      });
    },
  },
  mounted() {
    this.info = this.getItem("userInfo");
    this.getAjax(
      "api/user/personal",
      {
        uid: this.info.id,
      },
      "post"
    ).then((res) => {
      this.info = res.data;
      this.setItem('userInfo',this.info);
      this.email = this.info.email;
      this.username = this.info.nickname;
    });
  },
};
</script>

<style scoped lang="less">
</style>
